import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          company: descriptor0
          statement: descriptor1
          headerLinks {
            ... on Craft_headerLinks_link_BlockType {
              uid
              headLink {
                url
                text
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                }
              }
            }
          }
          privacy: link0 {
            url
            text
          }
          phone: link1 {
            url
            text
          }
          email: link2 {
            url
            text
          }
          social {
            ... on Craft_social_account_BlockType {
              uid
              platform
              accounturl
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { company, statement, headerLinks, privacy, phone, email, social } =
    globalSet;
  return {
    company,
    statement,
    headerLinks,
    privacy,
    phone,
    email,
    social,
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
